$primary-color: #FFD015;
$second-color: #040506;
$third-color: #12151A;

$rgb-primary-color: 255, 208, 21; //--primary-color *
$rgb-second-color: 4, 5, 6; //--second-color *
$rgb-third-color: 18, 21, 26; //--third-color *

@import "../../../src/styles/vars.scss";

@import "../../../src/styles/styles.scss";

//* Inter Tight
@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//* Grunge
@font-face {
    font-family: 'Grunge Font';
    src: url('assets/fonts/Grunge/GrungeFont-Regular.woff2') format('woff2'),
        url('assets/fonts/Grunge/GrungeFont-Regular.woff') format('woff'),
        url('assets/fonts/Grunge/GrungeFont-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Grunge Font Swashes';
    src: url('assets/fonts/Grunge/GrungeFontSwashes-Regular.woff2') format('woff2'),
        url('assets/fonts/Grunge/GrungeFontSwashes-Regular.woff') format('woff'),
        url('assets/fonts/Grunge/GrungeFontSwashes-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

body.webapp-site {
    --font-title: 'Grunge Font', sans-serif;
    --font-subtext: 'Inter Tight', sans-serif;
    --font-text: 'Inter Tight', sans-serif;
    background-color: var(--second-color);

    // General
    main {
        position: relative;
        z-index: 1;
    }
    
    .dropdown_menu_cy-item {
        .dropdown_menu_cy-action {
            color: white;
            text-transform: none;
            font-weight: 600;
        }
        .dropdown_menu_cy-content {
            .dropdown_menu_cy-content-int {
                color: #fff;
                background-color: var(--second-color);
            }
        }
    }

    mat-dialog-container {
        --mdc-dialog-container-color: var(--third-color);
        color: white;
    }

    .custom__card-1 {
        border: 1px solid var(--third-color);
        background-color: var(--third-color);
        .content__img {
            width: 100%;
            display: block;
            border-radius: 8px;
            background-color: var(--third-color);
            &.default__ctt-image {
                min-height: 210px;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    width: auto;
                    height: auto;
                    max-width: 100%;
                    max-height: 100%;
                    display: block;
                    margin: auto;
                    border-radius: 0;
                }
            }
            img {
                width: auto;
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
        }
        .content__desc {
            padding: 1rem;
            .subtext__card {
                font-size: 14px;
                line-height: normal;
                margin: 0;
                margin-bottom: 8px;
                color: #8d8d8d;
            }
            .title__card {
                font-size: 25px;
                font-weight: 300;
                font-family: var(--font-title);
                line-height: normal;
                margin: 0;
                margin-bottom: 10px;
                color: var(--primary-color);
            }
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
        .content__price {
            color: white;
            font-size: 1.25rem;
            line-height: 1.75rem;
        }
        .content__btns {
            padding: 1rem;
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-end;
            gap: 10px;
            .btn_buy {
                width: 100%;
                background-color: var(--primary-color);
                color: var(--second-color);
            }
        }
    }
    .custom__card-select {
        padding: 1rem;
        border: 2px solid #EAECF0;
        border-radius: 8px;
        width: 100%;
        cursor: pointer;
        user-select: none;
        &.active {
            pointer-events: none;
            cursor: default;
            border-color: var(--second-color);
            background-color: rgba(var(--rgb-second-color), 0.025);
            .content__head span {
                border-color: var(--second-color);
                &::before {
                    transform: scale(1);
                }
            }
        }
        .content__head {
            width: 100%;
            display: grid;
            grid-template-columns: minmax(0,20px) minmax(0,1fr);
            gap: 10px;
            align-items: center;
            span {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 2px solid #EAECF0;
                position: relative;
                &::before {
                    content: "";
                    display: block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: var(--second-color);
                    transition: all ease-in-out 0.2s;
                    transform: scale(0);
                    transform-origin: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .title__card {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                margin: 0;
            }
        }
        .content__desc {
            padding: 1rem 0;
            .text__card {
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                margin: 0;
            }
        }
    }

    .content__payments {
        border: 1px solid var(--third-color);
        .content__header {
            background: var(--third-color);
            .content__profile {
                background: var(--third-color);
                .profile__img {
                    display: none;
                }
                .ml-\[10px\] {
                    margin: 0;
                }
                .profile__name {
                    display: none;
                }
                .card__status {
                    border: 1px solid var(--second-color);
                    background-color: var(--second-color);
                    color: #fff;
                    &:nth-child(2) {
                        border: 0;
                        background: transparent;
                        font-size: 17px;
                        padding: 0;
                        color: var(--primary-color);
                        font-weight: 600;
                    }
                    &:nth-child(3) {
                        display: none;
                    }
                }
            }
        }
    }

    .desc__profile {
        .profile__name {
            color: #fff;
        }
    }

    .bg__auth {
        background-image: url('assets/img/general/bg-auth.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }

    .bg__third-400 {
        background-color: rgba(var(--rgb-third-color), 0.4);
    }

    .mat-mdc-icon-button {
        --mdc-icon-button-disabled-icon-color: #717171;
        --mat-paginator-disabled-icon-color: #717171;
    }
    .cy__btn {
        &.mdc-button {
            &.btn__primary {
                color: var(--second-color);
            }
        }
    }

    // Auth - start
    .auth__content {
        .input_group {
            input, select, textarea {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
            }
            input[type='password'] ~ .input_hide {
                background-color: var(--second-color);
                color: #fff;
            }
            .custom__mat-select .mat-mdc-select-trigger {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
                color: #fff;
                --mat-select-placeholder-text-color: #6b7280;
            }
        }
    }

    .custom__mat-select {
        .mat-mdc-select-trigger {
            border-color: var(--third-color);
            background-color: transparent;
            color: #fff;
        }
        .mat-mdc-select-arrow {
            --mat-select-enabled-arrow-color: #ddd;
        }
    }

    .status__soon {
        background-color: var(--primary-color);
    }

    .profile__settings {
        color: #fff;
        padding: 0;
        .card__ps {
            border-color: var(--third-color);
            background: #12151a66;
            border-radius: 0;
            .input_group {
                label {
                    color: var(--primary-color);
                    font-size: 1.125rem;
                    line-height: 1.75rem;
                }
            }
        }
        .current__profile-card-ps {
            display: none !important;
            & + .current__profile-card-ps-space {
                display: none;
            }
        }
        .title__ps {
            font-size: 1.125rem;
            line-height: 1.75rem;
            color: var(--primary-color);
            font-family: var(--font-title);
        }
        .current__profile-ps {
            font-size: 1.125rem;
            line-height: 1.75rem;
            font-weight: 600;
        }
        .cy__btn.mdc-button.btn__second.btn__change-profile {
            border-color: var(--third-color);
            background-color: var(--third-color);
            // color: var(--primary-color);
            color: white;
        }
    }
    .process_cart {
        color: #fff;
        position: relative;
        z-index: 0;
        .title__pc {
            color: var(--primary-color);
            text-transform: uppercase;
            font-size: 1.875rem;
            line-height: 2.25rem;
            font-family: var(--font-title);
        }
        .subtitle__pc {
            color: var(--primary-color);
            font-size: 1.125rem;
            line-height: 1.75rem;
        }
        .card__pc {
            background-color: #12151a66;
            border-color: var(--third-color);
            .input_group {
                input, select, textarea {
                    background: rgba(255, 255, 255, 0.059);
                    border: var(--second-color);
                }
            }
        }
        .order__pc {
            border: 1px solid var(--second-color);
            background-color: var(--second-color);
            color: #fff;
            overflow: hidden;
            table {
                th {
                    background-color: rgba(249, 250, 251, 0.05);
                    border-bottom: 1px solid var(--second-color);
                }
                td {
                    border-bottom: 1px solid var(--third-color);
                }
            }
        }
        .order-total__pc {
            border: 1px solid #fff;
            color: #fff;
            background-color: var(--second-color);
            font-weight: 500;
        }
        .btn__pay, .btn__pc {
            background-color: var(--primary-color);
            color: var(--second-color);
        }
    }
    .content__pcs {
        width: 1024px;
        display: block;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        overflow: hidden;
        max-width: 100%;
        .content__step-pcs {
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            &.active__step {
                .icon__step-pcs {
                    .ctt__icon-pcs {
                        background-color: var(--primary-color);
                    }
                }
                .title__pcs {
                    color: var(--primary-color);
                }
            }
            .icon__step-pcs {
                .ctt__icon-pcs {
                    background-color: #fff;
                }
            }
            .body__step-pcs {
                color: #fff;
            }
        }
    }

    // .ctt__checkout {
    //     .profile__settings {
    //         position: fixed;
    //         bottom: 0;
    //         left: 0;
    //         width: 400px;
    //         max-height: 80%;
    //         background-color: var(--second-color);
    //         z-index: 1;
    //         overflow: auto;
    //     }
    // }

    .payments {
        color: #fff;
        .title__payments {
            color: var(--primary-color);
            // text-transform: uppercase;
            font-weight: 500;
            font-size: 28px;
            line-height: normal;
            font-family: var(--font-title);
        }
        .dataTable__cap {
            --mat-paginator-container-text-color: #fff;
            mat-table, mat-cell, mat-header-cell {
                color: #fff;
            }
        }
        .status {
            background-color: rgba(var(--rgb-primary-color), 0.85);
            color: var(--second-color);
        }
    }
    
    mat-dialog-container {
        color: #fff;
        .mat-mdc-dialog-title h3 {
            font-size: 1.5rem;
            line-height: 2rem;
            color: var(--primary-color);
        }
        .mdc-dialog__content {
            color: #fff;
        }
        .input_group {
            input, select, textarea {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
            }
            .custom__mat-select .mat-mdc-select-trigger {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
                color: #fff;
                --mat-select-placeholder-text-color: #6b7280;
            }
        }
    }

    .card-general {
        background-color: var(--third-color);
        color: #fff;
        .title__card {
            font-family: var(--font-title);
            font-size: 1.5rem;
            &::before {
                display: none;
            }
        }
        .btns__card-account {
            text-align: center;
        }
        &.card__general-account, &.card__security-account {
            background-color: transparent;
        }
        .input_group {
            input, select, textarea {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
            }
            input[type='password'] ~ .input_hide {
                background-color: var(--second-color);
                color: #fff;
            }
            .custom__mat-select .mat-mdc-select-trigger {
                background: rgba(255, 255, 255, 0.059);
                border: var(--second-color);
                color: #fff;
                --mat-select-placeholder-text-color: #6b7280;
            }
        }
    }

    .content__congratulations {
        background-color: black;
    }

    table:not(.custom-table-design):not(.mat-calendar-table) {
        border-radius: 0;
        thead th {
            background-color: transparent;
            color: white;
        }
    }

    .loading_wrapper {
        .backdrop_loading {
            background-color: rgba(255, 255, 255, 0.3);
        }
        .loading__content {
            background: transparent;
        }
    }

    .ctt__checkout {
        .sidebar__acquire {
            position: unset;
        }
        .ctt__checkout-divisor {
            grid-template-columns: minmax(0, 1fr) !important;
        }
    }

    .disclaimer__header {
        background: rgba(255, 255, 255, 0.078);
        nav {
            padding: 10px;
            .disclaimer__header-left__dt {
                order: 1;
            }
            .disclaimer__header-right__dt {
                order: 0;
            }
            .content__link-sites {
                .cls-item .clsi-link {
                    font-size: 20px
                }
                .cls-separator {
                    opacity: 0;
                }
            }
        }
    }

    .content__shop {
        .title__page {
            color: var(--primary-color);
        }
        .content__shop-events {
            display: grid;
            grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
            gap: 1rem;
            .shop__events-item {
                background-color: rgba(255, 255, 255, 0.078);
                background-image: none;
                .shop__events-item-event-detail {
                    h3 {
                        font-size: 40px;
                        color: var(--primary-color);
                    }
                    h5 {
                        font-size: 30px;
                    }
                }
                .shop__events-item-event-detail-btns .buy__tickets.cy__btn.mdc-button {
                    background-color: var(--primary-color);
                    text-transform: unset;
                    font-family: var(--font-text);
                    border-color: var(--primary-color);
                }
            }
        }
    }

    .content__ticketing-wrapper {
        background-color: rgba(18, 21, 26, 0.4);
        border: 1px solid var(--third-color);
        color: white;
        .title__ticketing {
            color: var(--primary-color);
            font-family: var(--font-text);
            font-size: 1.125rem;
            line-height: 1.75rem;
            text-align: left;
            text-transform: unset;
            font-weight: 400;
        }
    }

    .stitle__ticketing {
        color: var(--primary-color);
        text-transform: uppercase;
        font-size: 1.875rem;
        line-height: 2.25rem;
        font-family: var(--font-title);
    }

    .content__ticket {
        background: black;
        border-color: #141414;
        h4 {
            font-size: 25px;
        }
        h5 {
            color: white;
            font-family: var(--font-text);
            text-transform: unset;
        }
    }

    .content__ticket-btns {
        border: 0;
        box-shadow: none;
        background-color: transparent;
        button {
            width: calc(100% - 4px) !important;
            border-radius: 10px;
            &:hover {
                background: rgba(255, 255, 255, 0.078);
            }
        }
        .btn__minus {
            margin-right: 4px;
        }
        .btn__plus {
            margin-left: 4px;
        }
        input {
            border: white;
            border-radius: 10px;
            background-color: rgba(255, 255, 255, 0.251);
            color: white;
        }
    }

    .cart__ticketing-section {
        .title__ticketing {
            color: var(--primary-color);
            font-family: var(--font-text);
            font-size: 1.125rem;
            line-height: 1.75rem;
            text-align: left;
            text-transform: unset;
            font-weight: 400;
        }
        hr {
            border-color: transparent;
        }
        .cart__title-ticketing {
            color: white;
            &::before {
                background-color: transparent;
            }
            span {
                background-color: transparent;
            }
        }
        .content__cart {
            border-color: var(--third-color);
            background: rgba(18, 21, 26, 0.4);
            color: white;
            .cart__item {
                border-color: black;
                border-radius: 0;
                background-color: black;
            }
            .cart__countdown {
                h5 {
                    color: var(--primary-color);
                }
                .cart__countdown-info img {
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                    object-position: center;
                    border: 3px solid #DDD;
                    border-radius: 50%;
                }
            }
            .cart__item-detail {
                h4 {
                    font-size: 20px;
                }
                h5 {
                    color: white;
                    text-transform: unset;
                    font-family: var(--font-text);
                }
            }
            .cart__item-quantity {
                color: white;
                font-family: var(--font-text);
            }

            .cy__btn.mdc-button.btn__outline.btn_clean-cart {
                border-color: var(--second-color);
                background-color: var(--second-color);
                color: #fff;
            }
            .cy__btn.mdc-button.btn__second.btn_pay-cart {
                border-color: var(--primary-color);
                background-color: var(--primary-color);
                color: var(--second-color);
            }
        }

        .cart__total {
            border: 1px solid #fff;
            color: #fff;
            background-color: var(--second-color);
            font-weight: 500;
            border-radius: 0;
            h5 {
                color: currentColor;
                font-size: 1rem;
                font-family: var(--font-text);
                text-transform: unset;
                b {
                    font-weight: 500;
                }
            }
        }
    }

    .ctt__fixture-section {
        display: grid;
        grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
        gap: 1rem;
        .ctt__fixture-section-title {
            grid-column: 1 / -1;
            color: white;
            text-align: left;
        }
        .fixture__item {
            background: rgba(255, 255, 255, 0.178);
            color: white;
            .text-secondary {
                color: white;
            }
            .fixture__item-vs {
                img {
                    width: 78px;
                }
            }
            .fixture__item-team-name {
                color: var(--primary-color);
            }
            .fixture__item-btns {
                width: 100%;
                small {
                    text-align: center;
                }
            }
            .fixture__item-header {
                .fixture__item-header-stage {
                    color: var(--primary-color);
                }
            }
        }
    }

    .content__scroll-sidebar {
        color: white;
    }

    .home__section-1 {
        .bg-white {
            background: rgba(255, 255, 255, 0.178);
            color: white;
            .text-secondary {
                color: white;
            }
            h5 {
                &.font__title {
                    font-family: var(--font-text);
                }
            }
            .cy__btn.mdc-button {
                font-family: var(--font-text);
                text-transform: unset;
                &.btn__second {
                    background-color: var(--primary-color);
                    color: black;
                }
            }
        }
    }

    .home__section-3 {
        background: transparent;
        // background: rgba(255, 255, 255, 0.178);
        color: white;
        .text-secondary {
            color: white;
        }
        .content__card-section-3 {
            // border-color: var(--second-color);
            // background-color: var(--second-color);
            border-color: rgba(255, 255, 255, 0.178);
            background-color: rgba(255, 255, 255, 0.178);
        }
    }

    .home__section-5 {
        background: rgba(255, 255, 255, 0.178);
        color: white;
        .text-secondary {
            color: var(--primary-color);
        }
        .content__card-section-3 {
            border-color: var(--second-color);
            background-color: var(--second-color);
            text-align: center;
        }
        .content__swiper {
            .nav__slider {
                color: white;
            }
            .pagination__slider .swiper-pagination-bullet {
                background-color: white;
                opacity: 0.5;
                width: 12px;
                height: 12px;
                &.swiper-pagination-bullet-active {
                    background-color: white;
                    opacity: 1;
                }
            }
            .swiper-pagination-lock {
                display: none;
            }
        }
        .cy__btn.mdc-button.btn__second {
            border-color: var(--primary-color);
            background-color: var(--primary-color);
            color: var(--second-color);
            text-transform: unset;
            font-family: var(--font-text);
        }
    }

    .home__section-1, .home__section-3, .home__section-5 {
        max-width: calc(100% - 1rem);
        margin: 0 auto;
    }

    .status {
        color: rgba(255, 255, 255, 0.6);
    }

    .content__benefits-points {
        // background: rgba(255, 255, 255, 0.178);
        background: transparent;
        color: white;
        .text-secondary {
            color: var(--primary-color);
        }
    }

    .content__benefits .content__benefits-details {
        .content__benefits-wrapper ul {
            list-style: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="0.8rem" height="0.8rem" stroke-width="4.5" stroke="#ffffff"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>');
            p {
                color: rgba(255, 255, 255, 0.6);
            }
        }
        .content__benefits-data .content__benefits-data-detail {
            background: rgba(255, 255, 255, 0.178);
            border-color: rgba(255, 255, 255, 0.178);
            hr {
                border-color: rgba(255, 255, 255, 0.178);
            }
            h4 {
                color: white;
            }
            h6 {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .content__benefits-categories {
        .content__benefits-categories-steps-header {
            &::before {
                background: rgba(255, 255, 255, 0.178);
            }
            .content__benefits-categories-steps .content__benefits-categories-step button {
                color: var(--second-color);
            }
        }
        .content__benefits-categories-steps-body .content__benefits-categories-step-body {
            background: rgba(255, 255, 255, 0.178);
            &.type__2 {
                background: transparent;
                .content__product-cmr-points {
                    background: rgba(255, 255, 255, 0.178);
                    border-color: transparent;
                    color: white;
                }
            }
            .content__product-unique-discounts {
                border-color: black;
                background: black;
                color: white;
                .content__product-unique-discounts-image {
                    background: white;
                    .content__product-unique-discounts-discount {
                        background-color: var(--primary-color);
                        color: black;
                        font-weight: 600;
                    }
                }
            }
            .content__benefits-categories-step-body-detail-card {
                border-color: black;
                background: black;
                color: white;
                h5 {
                    color: var(--primary-color);
                }
                .content__benefits-categories-step-body-detail-card-info {
                    .remove {
                        color: rgba(255, 255, 255, 0.6);
                    }
                    .info {
                        color: rgba(255, 255, 255, 0.6);
                    }
                    .add {
                        color: rgba(255, 255, 255, 0.6);
                    }
                }
            }
            p {
                color: rgba(255, 255, 255, 0.8);
            }
            h5 {
                color: rgba(255, 255, 255, 0.6);
            }
            .cy__btn.mdc-button.btn__outline {
                background-color: var(--primary-color);
                border-color: var(--primary-color);
            }
            .stepb__icon {
                color: var(--primary-color);
            }
        }
    }

    .content__product-discounts {
        background: rgba(255, 255, 255, 0.178);
        .content__product-discounts-detail {
            h6 {
                color: white;
            }
            h5 {
                color: white;
            }
            .price {
                color: var(--primary-color);
            }
            .old__price {
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    .content__crm-points-cy {
        background: transparent;
        table {
            color: white;
            thead th {
                background: var(--third-color) !important;
            }
            border-color: var(--third-color);
            tbody tr:not(.no-border):not(:last-child):after {
                background-color: var(--third-color) !important;
            }
        }
        .content__crm-points-cy-title {
            color: var(--primary-color);
        }
    }

    .dropdown_menu_cy-item .dropdown_menu_cy-content .dropdown_menu_cy-content-int {
        box-shadow: 1px -5px 6px 0px rgba(var(--rgb-primary-color), 0.15), 0px 12px 16px -4px rgba(var(--rgb-primary-color), 0.17);
    }

    .content__service-discount {
        .content__service-discount-info {
            background: rgba(255, 255, 255, 0.178);
            .content__service-discount-detail {
                color: white;
                .content__service-discount-detail-categories span {
                    color: var(--second-color);
                }
            }
            .content__service-discount-image {
                background-color: rgba(255, 255, 255, 1);
            }
        }
        .content__service-discount-more-information {
            .content__service-discount-more-information-item {
                background: rgba(255, 255, 255, 0.178);
                .content__service-discount-more-information-item-details .content__service-discount-more-information-item-detail div p {
                    color: white;
                }
                hr {
                    border-color: var(--third-color);
                }
            }
        }
    }

    .content__profile {
        background: transparent;
        color: white;
        .text-secondary {
            color: white;
        }
        .content__profile-title {
            color: var(--primary-color);
            font-family: var(--font-title);
            font-size: 30px;
        }
        input, select, mat-select .mat-mdc-select-trigger {
            background: rgba(255, 255, 255, 0.059);
            border: var(--second-color);
        }

        .cy__btn.mdc-button.btn__second {
            text-transform: unset;
            font-family: var(--font-text);
        }
    }

    // Responsive
    
    @media (min-width: 768px) {
        mat-dialog-container {
            .mat-mdc-dialog-title h3 {
                font-size: 1.875rem;
                line-height: 2.25rem;
            }
        }
        .payments {
            .title__payments {
                font-size: 42px;
            }
        }
        .profile__settings {
            .title__ps {
                font-size: 1.5rem;
                line-height: 2rem;
            }
        }
        .content__pcs {
            justify-content: space-between;
        }
    }

    @media (max-width: 1439px) {
        .content__shop .content__shop-events .shop__events-item {
            grid-template-columns: minmax(0, 1fr);
            padding: 1.5rem 0;
            .shop__events-item-event-img.center__img {
                margin: 0 auto;
            }
        }
    }

    @media (max-width: 1439px) {
        .content__shop .content__shop-events .shop__events-item.type__event .shop__events-item-event-detail {
            h3 {
                font-size: 35px;
            }
            h5 {
                font-size: 25px;
            }
        }
    }

    @media (max-width: 767px) {
        .content__shop .content__shop-events, .ctt__fixture-section {
            grid-template-columns: minmax(0, 1fr);
        }
    }
}